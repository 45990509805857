import React, { useState, useEffect } from "react";
// import { useUser } from "../../context/UserContext";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
// import { Session } from "@supabase/supabase-js";
// import { supabase } from "../../utils/supabaseClient";

const NavMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const { user, setUser } = useUser(); // Use context to manage user state
  // const [loading, setLoading] = useState(true); // Loading state

  // useEffect(() => {
  //   const fetchSession = async () => {
  //     const {
  //       data: { session },
  //     } = await supabase.auth.getSession();
  //     setUser(session?.user ?? null);
  //     setLoading(false);
  //   };

  //   fetchSession();

  //   const { data: authListener } = supabase.auth.onAuthStateChange(
  //     (_event: string, session: Session | null) => {
  //       setUser(session?.user ?? null);
  //     }
  //   );

  //   return () => {
  //     authListener?.subscription.unsubscribe();
  //   };
  // }, [setUser]);

  // const handleLogin = async () => {
  //   const { error } = await supabase.auth.signInWithOAuth({
  //     provider: "google",
  //   });

  //   if (error) {
  //     console.error("Login error:", error);
  //   }
  // };

  // const handleLogout = async () => {
  //   const { error } = await supabase.auth.signOut();

  //   if (error) {
  //     console.error("Logout error:", error);
  //   }
  // };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const hardRefresh = () => {
    window.scrollTo(0, 0);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle: React.CSSProperties = {
    backgroundColor: scrollPosition > 100 ? "#1b1726" : "#1b1b2a",
    transition: "background-color 0.3s ease",
    padding: "20px",
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 1000,
  };

  return (
    <div className="nav-menu">
      {/* Side drawer */}
      <div className={`drawer ${isMenuOpen ? "open" : ""}`}>
        <div className="drawer-content">
          <ul>
            <li className="my-3">
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li className="my-3">
              <Link to="/remote_jobs" onClick={toggleMenu}>
                Remote Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/engineering_jobs" onClick={toggleMenu}>
                Engineering Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/company" onClick={toggleMenu}>
                Top Companies
              </Link>
            </li>
            <li className="my-3">
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li className="my-3">
              <Link to="/blog" onClick={toggleMenu}>
                Blog
              </Link>
            </li>
            <li className="my-3">
              <Link to="/news" onClick={toggleMenu}>
                News
              </Link>
            </li>
            <li className="my-3">
              <Link to="mailto:aijobsterworks@gmail.com">Contact</Link>
            </li>
            {/* {loading ? (
              <li className="text-white">Loading...</li>
            ) : (
              <li className="my-3 mt-5">
                {user ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={user.user_metadata.picture}
                      alt="Profile"
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: "50%",
                      }}
                    />
                    <FiPower
                      size={24}
                      color="#fff"
                      className="ms-3"
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <div onClick={handleLogin} style={{ cursor: "pointer" }}>
                    <li className="my-3">Login</li>
                  </div>
                )}
              </li>
            )} */}
          </ul>
        </div>
      </div>

      {/* Web Header */}
      <div
        style={headerStyle}
        className="bg fixed-top w-100 d-flex align-items-center p-2 web-menu"
      >
        <Link to="/" className="d-flex align-items-center col-md-2">
          <img src={logo} alt="AI Jobster" className="logo" />
          <h5 className="mx-1 m-0 text-white fw-bolder">AI Jobster</h5>
        </Link>
        <div className="col-md-10 d-flex justify-content-end hide-menu">
          <li>
            <a onClick={hardRefresh} href="/">
              Home
            </a>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/remote_jobs">Remote Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/engineering_jobs">Engineering Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/company">Top Companies</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/about">About</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/news">News</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="mailto:aijobsterworks@gmail.com">Contact</Link>
          </li>
        </div>
        {/* <ul className="d-flex m-0 col-md-2 justify-content-end hide-menu">
          <li className="ms-2">
            {user ? (
              <div>
                <FiPower
                  className="me-3"
                  size={16}
                  color="#fff"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={user.user_metadata.picture}
                  alt="Profile"
                  style={{ width: 30, height: 30, borderRadius: "50%" }}
                />
              </div>
            ) : (
              <div onClick={handleLogin} style={{ cursor: "pointer" }}>
                <li className="text-white">Login</li>
              </div>
            )}
          </li>
        </ul> */}
      </div>

      {/* Menu toggle button */}
      <div className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
      </div>
    </div>
  );
};

export default NavMenu;
