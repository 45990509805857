import axios from "axios";
import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import moment from "moment";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import ErrorAlertComponent from "./ErrorAlertComponent";
import SpinnerComponent from "./SpinnerComponent";

interface Article {
  title: string;
  description: string;
  url: string;
  author: string;
  publishedAt: string;
  source: { id: string; name: string };
  urlToImage?: string;
}

const LatestNews: React.FC = () => {
  const [news, setNews] = useState<Article[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const API_KEY = "3eee532b7893405d9b152327547d04a8";
  const API_URL = `https://newsapi.org/v2/everything?q=artificial+intelligence+jobs&language=en&apiKey=${API_KEY}`;

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(API_URL);
        const sortedArticles = response.data.articles.sort(
          (a: Article, b: Article) => {
            return (
              new Date(b.publishedAt).getTime() -
              new Date(a.publishedAt).getTime()
            );
          }
        );
        setNews(sortedArticles);
      } catch (err) {
        setError("Failed to fetch news.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const articlesWithImages = news.filter((article) => article.urlToImage);

  return (
    <div className="mt">
      <div>
        <Banner title="Trending AI News" />
        {loading ? (
          <SpinnerComponent animation="border" variant="primary" />
        ) : error ? (
          <ErrorAlertComponent error={error} />
        ) : (
          <div className="container mt">
            <div className="row">
              {/* {articlesWithImages.map((article) => (
            <div className="col-lg-5 mb-4" key={article.url}>
              <a
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
                className="card-link"
              >
                <div className="card crx">
                  <div className="position-relative">
                    <img
                      src={article.urlToImage}
                      alt={article.title}
                      className="card-img-top bg-light img-fluid"
                    />
                    <h5 className="p-3">{article.title}</h5>
                  </div>
                </div>
              </a>
            </div>
          ))} */}
              <div className="blog-list">
                {articlesWithImages.length > 0 ? (
                  articlesWithImages.map((content, index) => (
                    <div
                      className="blog-item"
                      key={index}
                      style={{ animationDelay: `${index * 0.1}s` }}
                    >
                      <div className="card-body">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-4">
                            <img
                              className="img-fluid img-responsive"
                              style={{ objectFit: "cover" }}
                              src={content.urlToImage}
                              alt={content.urlToImage}
                            />
                          </div>
                          <div className="col-md-8 p-3">
                            <h4 className="m-0">{content.title}</h4>
                            <p className="small">{content.description}</p>
                            <span style={{ color: "#999", fontSize: 14 }}>
                              {moment(content.publishedAt).format(
                                "hh:mm a MMM DD, YYYY"
                              )}{" "}
                              | {content.source.name}
                            </span>
                            <div className="text-right py-2">
                              <a
                                href={content.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Read More <FiArrowUpRight size={20} />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No match found</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestNews;
