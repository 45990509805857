// src/supabaseClient.ts
import { createClient, SupabaseClient } from "@supabase/supabase-js";

// Use environment variables for Supabase URL and anon key
const SUPABASE_URL: string = "https://eqebyicmxgeufijecmss.supabase.co";
const SUPABASE_ANON_KEY: string =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVxZWJ5aWNteGdldWZpamVjbXNzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY5ODA2MjEsImV4cCI6MjA0MjU1NjYyMX0.jyRkLBz1nk7WqPABmUA7tohTev0pp8fN9a9UoIknzOo";

// Create Supabase client
export const supabase: SupabaseClient = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY
);